import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {epdAstralService, companyService} from "../../../../services";
import {Button, Col, Form, Icon, Input, Modal, notification, Row, Steps, Upload} from "antd";
import InputField from "../../../input-field";
import FieldBlock from "../../../field-block";
import ModalCreateSignature from "../create-signature";
import CreateSignature from "../create-signature";

export default function ModalLoginApiCompany({type = 'login', userId, isShow, onCloseModal, setIsNeedUpdate}) {
  const {Step} = Steps;
  const {Option} = Select;

  const [currentStep, setCurrentStep] = useState(0);
  const [stepErrorStatus, setStepErrorStatus]  = useState(null);
  const [username, setUsername] = useState('sergey.y@softwer.ru');
  const [password, setPassword] = useState('QPwoei84');
  const [legal_entity_id, setLegalEntityId] = useState('');
  const [sign, setSign] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [message, setMessage] = useState('');

  const [signature, setSignature] = useState('');
  const [companiesList, setCompaniesList] = useState([]);

  const [isAvailableNextStep, setIsAvailableNextStep] = useState(false);

  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  }

  const prev = () => {
    setCurrentStep(currentStep - 1);
  }

  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined") ? fieldsErrors[nameField][0] : null;
  };
  const loginApiCompany = () => {
    if (username.length > 0 && password.length > 0) {
      epdAstralService.loginApiCompany({username: username, password: password, sign: sign})
        .then(resp => {
          if (resp.status === 'success') {
            notification.success({
              message: "Логин от имени компании ЭДО ЭТрН",
              description: resp.message,
            });
            setStepErrorStatus(null);
            onCloseModal(null);
            setIsNeedUpdate(true);
          } else if (resp.status === 'error') {
            setFieldsErrors(resp.errors);
            setStepErrorStatus(2);
            notification.error({
              message: "Логин от имени компании ЭДО ЭТрН",
              description: resp.message,
            });
          }
        })
        .catch((err) => {
          notification.error({
            message: "Логин от имени компании ЭДО ЭТрН",
            description: err,
          });
        });
    }
  }

  const registerApiCompany = () => {
    if (username.length > 0 && password.length > 0) {
      epdAstralService.registerApiCompany({username: username, password: password, sign: sign, legal_entity_id: legal_entity_id})
        .then(resp => {
          if (resp.status === 'success') {
            notification.success({
              message: "Регистрации компании ЭДО ЭТрН",
              description: resp.message,
            });
            setStepErrorStatus(null);
            onCloseModal(null);
            setIsNeedUpdate(true);
          } else if (resp.status === 'error') {
            setFieldsErrors(resp.errors);
            setStepErrorStatus(2);
            notification.error({
              message: "Регистрации компании ЭДО ЭТрН",
              description: resp.message,
            });
          }
        })
        .catch((err) => {
          notification.error({
            message: "Регистрации компании ЭДО ЭТрН",
            description: err,
          });
        });
    }
  }

  useEffect(() => {
    if (username.length > 0 && password.length > 0 && currentStep === 1) {
      epdAstralService.getBase64Auth({username, password})
        .then(resp => {
          notification.info({
            message: "Токен для создания откреплённой подписи", description: resp.message,
          });
          setMessage(resp[0]);
        })
        .catch((err) => {
          notification.error({
            message: "Токен для создания откреплённой подписи", description: err,
          });
        });
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 0) {
      if (username.length > 0 && password.length > 0 && (type !== 'register' || legal_entity_id > 0)) {
        setIsAvailableNextStep(true);
      } else {
        setIsAvailableNextStep(false);
      }
    }
    if (currentStep === 1) {
      if (message.length > 0 && signature.length > 0) {
        setIsAvailableNextStep(true);
      } else {
        setIsAvailableNextStep(false);
      }
    }
  }, [username, password, legal_entity_id, message, signature, currentStep]);

  useEffect(() => {
    if (currentStep === 2 && signature.length > 0) {
      const blob = new Blob([signature], {type: "application/x-pkcs7-signature"});
      const file = new File([blob], `sign-${userId}.p7s`, {type: blob.type, lastModified: Date.now()});
      setSign(file);
      // exportSign();
    }
  }, [currentStep]);

  useEffect(() => {
    if (isShow) {
      companyService
      .getEntitiesList()
      .then(resp => {
          setCompaniesList(resp);
      })
      .catch(err => {
          notification.error({
              message: 'Загрузка юр.лиц перевозчика',
              description: err
          });
      });
    }
  }, [isShow]);

  const exportSign = () => {
    const blob = new Blob([signature], {type: "application/x-pkcs7-signature"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `sign-${userId}.p7s`;
    link.href = url;
    link.click();
  }

  let modalTitle = 'Авторизация компании';
  if(type === 'register') {
    modalTitle = 'Регистрация компании';
  }

  const steps = [
    {
      title: modalTitle,
      content: <>
        <Col span={24}>
          <InputField
            name="username" label="Логин"
            value={username} setValue={setUsername}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col span={24}>
          <InputField
            name="password" label="Пароль" type="password"
            value={password} setValue={setPassword}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        {type === 'register' &&
          <Col span={24}>
            {companiesList && companiesList.length && <>
              <span className="form-field-label">Юр. лицо</span>
              <Select
                size="default"
                defaultValue={legal_entity_id}
                value={legal_entity_id}
                style={{width: "100%"}}
                showArrow={true}
                optionFilterProp="children"
                placeholder="Выберите организацию"
                onChange= {(value) => {
                  setLegalEntityId(value)
                }}
              >
                {companiesList.map((item) => {
                  return (
                    <Option key={item.Id} value={item.Id}>
                      {item.OrgForm} {item.Name}
                    </Option>
                  );
                })}
              </Select>
            </>
            }
          </Col>
        }
      </>,
    },
    {
      title: 'Подписать',
      content:
        <Col span={24}>
          <CreateSignature message={message} userId={userId} signature={signature}
                           setSignature={setSignature}/>
        </Col>,
    },
    {
      title: 'Отправить',
      content:
        <Col span={24}>

        </Col>,
    },
  ];

  return (<Modal
      title={<div>{`${modalTitle} ЭДО ЭТрН`}</div>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        onCloseModal(null);
      }}
      forceRender={true}
      footer={<div></div>}
    >
      <Steps current={currentStep} style={{marginBottom: 20, paddingBottom: 20}} status={stepErrorStatus === currentStep ? "error" : "process"}>
        {steps.map(item => (
          <Step key={item.title} title={item.title}/>
        ))}
      </Steps>
      <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
        {steps[currentStep].content}
      </Row>
      <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
        <Col>
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => next()} disabled={!isAvailableNextStep}>
              Далее
            </Button>
          )}
          {currentStep === steps.length - 1 && type === 'login' && (
            <Button type="primary" style={bigButtonStyle}
                    onClick={() => loginApiCompany()}
                    disabled={username.length === 0 || password.length === 0}>
              Отправить
            </Button>
          )}
          {currentStep === steps.length - 1 && type === 'register' && (
            <Button type="primary" style={bigButtonStyle}
                    onClick={() => registerApiCompany()}
                    disabled={username.length === 0 || password.length === 0}>
              Отправить
            </Button>
          )}
          {currentStep > 0 && (
            <Button style={{marginLeft: 8}} onClick={() => prev()}>
              Назад
            </Button>
          )}
        </Col>
      </Row>
    </Modal>
  )
}
