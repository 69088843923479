import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Table, Row, Icon, Tooltip, notification, Input} from "antd";
import {reportService} from "../../../../services/report.services";
import SelectField from "../../../select-field";
import InputField from '../../../input-field';
import {distributionCenterService, companyService} from "@services";
import ModalDeliveryInfo from "../../../modals/delivery-info";
import {httpRequest} from "../../../../services/http-request";
import ModalListOrdersDelivery from "../../../modals/list-orders-delivery";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import moment from "moment";

export default function StatsDeliveryLoadChanges() {
  const [loading, setLoading] = useState(false);
  const [isNeedUpdateReport, setIsNeedUpdateReport] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [dateStart, setDateStart] = useState(moment().startOf("month"));
  const [dateEnd, setDateEnd] = useState(moment().endOf("month"));

  const [deliveryNumber, setDeliveryNumber] = useState('');
  const [distCenters, setDistCenters] = useState([]);
  const [selectedDistCenters, setSelectedDistCenters] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [response, getResponse] = useState(0);
  const [deliveryInfo, setDeliveryInfo] = useState(undefined);

  const [showModalOrdersDelivery, setShowModalOrdersDelivery] = useState(false);
  const [responseOrder, getResponseOrder] = useState(0);

  // полученные данные отчета
  const [reportData, setReportData] = useState([]);
  const [reportTotal, setReportTotal] = useState([]);

  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
      ? fieldsErrors[nameField][0]
      : null;
  };

  // при первом открытии автоматически запрашиваем данные
  useEffect(() => {
    if (loading === false) {
      setLoading(true);
      setIsNeedUpdateReport(true);
      distributionCenterService.getCompanies()
        .then((response) => {
          setDistCenters(response);
        }).catch((errorMsg) => {
        setErrorMsg(errorMsg);
      });
    }
  }, []);

  useEffect(() => {
    if (isNeedUpdateReport) {
      // запрос на получение данных отчета
      reportService
        .getDeliveryLoadChanges(
          selectedDistCenters,
          dateStart.format("YYYY-MM-D"),
          dateEnd.format("YYYY-MM-D"),
          deliveryNumber
        )
        .then((reportData) => {
          let i = 0;
          //let reportFullDataParsed = reportData.List
          //  .filter((item) => item.child === false)
          //  .map((item) => {
          //    i++;
          //    return {
          //      key: `center` + i,
          //      distributionCenter: item.distributionCenter,
          //      data: []
          //    }
          //  });
          //reportFullDataParsed.forEach((distCenter) => {
          //  const filteredData = reportData.List.filter((item) => item.child === true && item.distributionCenter === distCenter.distributionCenter)
          //  distCenter.data = filteredData;
          //})
          let reportFullDataParsed = reportData.List;
          setIsNeedUpdateReport(false);
          setLoading(false);
          setReportData(reportFullDataParsed);
          setReportTotal(reportData.TotalInfo);

        }).catch((errorMsg) => {
        setIsNeedUpdateReport(false);
        setLoading(false);
        setErrorMsg(errorMsg);
      });
    }
  }, [isNeedUpdateReport])

  const closeModalHandler = () => {
    setShowModal(false);
    getResponse(0);
    setDeliveryInfo(undefined);
  }

  // получаем данные для модалки "История рейса"
  useEffect(() => {
    if (response !== 0) {
      let url = "delivery/get-delivery-history?deliveryId=" + response;
      httpRequest.get(url).then(function (res) {
        setDeliveryInfo(res.data);
        getResponse(0);
      }).catch((e) => {
        notification.error({
          message: "У Вас нет доступа!",
          description: e,
        });
        return false;
      })
    }

  }, [response]);

  // отображение фатальной ошибки
  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }

  // список столбцов таблиц
  const columns = [
    {
      title: '',
      width: 45
    },
    {
      title: <>Номер<br/>рейса</>,
      key: 'distributionCenter',
      dataIndex: 'distributionCenter',
      render: (text) => {
        return {
          children: <>{text}</>,
          props: {
            colSpan: 8,
          },
        }
      },
      width: 120
    },
    {
      title: <>Дата<br/>погрузки</>,
      render: (text) => {
        return {
          children: <></>,
          props: {
            colSpan: 0,
          },
        }
      },
      width: 120
    },
    {
      title: <>Старая дата</>,
      render: () => {
        return {
          children: <></>,
          props: {
            colSpan: 0,
          },
        }
      },
      width: 120
    },
    {
      title: <>Новая дата</>,
      render: () => {
        return {
          children: <></>,
          props: {
            colSpan: 0,
          },
        }
      },
      width: 120
    },
    {
      title: <>Запросил перенос</>,
      render: () => {
        return {
          children: <></>,
          props: {
            colSpan: 0,
          },
        }
      },
      width: 200
    },
    {
      title: <>Утвердил перенос</>,
      render: () => {
        return {
          children: <></>,
          props: {
            colSpan: 0,
          },
        }
      },
      width: 200
    },
    {
      title: <>Время создания переноса</>,
      render: () => {
        return {
          children: <></>,
          props: {
            colSpan: 0,
          },
        }
      },
      width: 120
    },
    {
      title: <>Время утверждения переноса</>,
      render: () => {
        return {
          children: <></>,
          props: {
            colSpan: 0,
          },
        }
      },
      width: 120
    },

  ];
  const nestedColumns = [
    {
      title: '',
      width: 45
    },
    {
      title: <></>,
      key: 'Number',
      render: (record) => (
        <a className="link link--underline"
           href="#"
           onClick={(e) => {
             e.preventDefault();
             setShowModal(true);
             getResponse(record.Id);
           }}
        >{record.Number}</a>
      ),
      width: 120
    },
    {
      title: <></>,
      dataIndex: 'DateLoad',
      key: 'DateLoad',
      width: 120,
      render: (text) => {
        return <>{moment(text).format("D.MM.YYYY")}</>
      }
    },
    {
      title: <></>,
      key: 'OldDate',
      width: 120,
      render: (record) => {
        return <>{record.Changes[0].OldDate ? moment(record.Changes[0].OldDate).format("D.MM.YYYY"): ''}</>
      }
    },
    {
      title: <></>,
      key: 'NewDate',
      className: "center",
      width: 120,
      render: (record) => {
        return <>{moment(record.Changes[0].NewDate).format("D.MM.YYYY")}</>
      }
    },
    {
      title: <></>,
      key: 'CreatedBy',
      className: "center",
      width: 200,
      render: (record) => {
        return <>{record.Changes[0].CreatedBy}</>
      }
    },
    {
      title: <></>,
      key: 'ProcessedBy',
      className: "center",
      width: 200,
      render: (record) => {
        return <>{record.Changes[0].ProcessedBy}</>
      }
    },
    {
      title: <></>,
      key: 'DateCreated',
      className: "center",
      width: 120,
      render: (record) => {
        return <>{moment(record.Changes[0].DateCreated).format("D.MM.YYYY HH:mm")}</>
      }
    },
    {
      title: <></>,
      key: 'DateProcessed',
      className: "center",
      width: 120,
      render: (record) => {
        return <>{moment(record.Changes[0].DateProcessed).format("D.MM.YYYY HH:mm")}</>
      }
    },
  ]

  const changesColumns = [
    {
      title: '',
      width: 45
    },
    {
      title: <></>,
      width: 120
    },
    {
      title: <></>,
      width: 120,
    },
    {
      title: <></>,
      key: 'OldDate',
      dataIndex: 'OldDate',
      width: 120,
      render: (text) => {
        return <>{text? moment(text).format("D.MM.YYYY") : ''}</>
      }
    },
    {
      title: <></>,
      key: 'NewDate',
      dataIndex: 'NewDate',
      className: "center",
      width: 120,
      render: (text) => {
        return <>{text? moment(text).format("D.MM.YYYY") : ''}</>
      }
    },
    {
      title: <></>,
      dataIndex: 'CreatedBy',
      key: 'CreatedBy',
      className: "center",
      width: 200,
    },
    {
      title: <></>,
      dataIndex: 'ProcessedBy',
      key: 'ProcessedBy',
      className: "center",
      width: 200,
    },
    {
      title: <></>,
      dataIndex: 'DateCreated',
      key: 'DateCreated',
      className: "center",
      width: 120,
      render: (text) => {
        return <>{text? moment(text).format("D.MM.YYYY HH:mm") : ''}</>
      }
    },
    {
      title: <></>,
      dataIndex: 'DateProcessed',
      key: 'DateProcessed',
      className: "center",
      width: 120,
      render: (text) => {
        return <>{text? moment(text).format("D.MM.YYYY HH:mm") : ''}</>
      }
    },
  ]

  return (
    <>
      <div className="reports__header">
        <h1 className="content-header">Отчет «Переносы даты загрузки рейса»</h1>
        <Icon type='filter'
              theme={isFilterOpen ? '' : "filled"}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen)
              }}
              className="reports__filter-toggle"/>
        {isFilterOpen && <div className={`reports__filter ${isFilterOpen ? 'is-open' : ""}`}>
          <Row gutter={12} style={{padding: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col lg={4} md={4} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <SelectField
                name="distributionCenters"
                selectedValue={selectedDistCenters}
                values={distCenters}
                onChange={(value) => {
                  setSelectedDistCenters(value);
                }}
                notFoundText="РЦ отсутствуют"
                getErrors={() => {
                }}
                style={{width: "100%"}}
                mode={'multiple'}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} style={{paddingRight: "10px"}}>
              <Input
                  name="number"
                  label="Номер рейса"
                  value={deliveryNumber}
                  placeholder='Номер рейса'
                  onChange={(e) => {
                    setDeliveryNumber(e.target.value);
                  }}
                  getFieldErrors={getFieldErrors}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateStart}
                format="DD.MM.YYYY"
                size="normal"
                name="dateStart"
                placeholder="Выберите дату начала периода"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  setDateStart(date);
                }}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={24} style={{paddingRight: "10px"}}>
              <DatePicker
                style={{width: "100%"}}
                defaultValue={dateEnd}
                format="DD.MM.YYYY"
                size="normal"
                name="dateEnd"
                placeholder="Выберите дату окончания периода"
                locale={localeDatePicker}
                allowClear={false}
                onChange={(date) => {
                  setDateEnd(date);
                }}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={24} style={{textAlign: 'right', height: '40px'}}>
              <Button type="primary"
                      htmlType="button"
                      onClick={() => {
                        setLoading(true);
                        setIsNeedUpdateReport(true);
                      }}
                      style={{backgroundColor: '#1CAA53', border: 'none', height: '100%', width: '100%'}}
              >
                Применить
              </Button>
            </Col>
          </Row>
        </div>}
        <Row gutter={12} style={{padding: "0", rowGap: "10px", marginTop: '10px'}} type="flex" justify="start">
          <Col lg={24} md={24} sm={24} xs={24} style={{textAlign: 'left', marginBottom: '5px'}}>Всего рейсов: {reportTotal.Total}</Col>
        </Row>
      </div>
      <div className="reports__table">
        <div className="reports__table-wrapper">
          <Col span={24}>
            <Table
              style={{width: 'max-content'}}
              columns={columns}
              dataSource={reportData}
              bordered={true}
              locale={{emptyText: "Данные отсутствуют"}}
              rowKey="key"
              size={"small"}
              loading={loading}
              pagination={false}
              expandedRowRender={record => {
                return <Table
                  columns={nestedColumns}
                  rowKey="number"
                  showHeader={false}
                  pagination={false}
                  size={"small"}
                  expandIconAsCell={false}
                  expandIconColumnIndex={1}
                  locale={{emptyText: "Данные отсутствуют"}}
                  bordered={true}
                  footer={() => ''}
                  dataSource={record.data}
                  expandedRowRender={record => {
                    return <Table
                      columns={changesColumns}
                      rowKey="number"
                      showHeader={false}
                      pagination={false}
                      size={"small"}
                      expandIconAsCell={false}
                      expandIconColumnIndex={1}
                      locale={{emptyText: "Предыдущие переносы отсутствуют"}}
                      bordered={true}
                      footer={() => ''}
                      dataSource={record.Changes.slice(1)}
                    />
                  }}
                />
              }}
              expandRowByClick={true}
              defaultExpandAllRows={false}
              expandIconAsCell={false}
              expandIconColumnIndex={0}
              footer={() => ''}
            />
          </Col>
        </div>
      </div>
      {deliveryInfo && <ModalDeliveryInfo
        deliveryInfo={deliveryInfo}
        isShow={showModal}
        setIsShow={closeModalHandler}
      />}
      {<ModalListOrdersDelivery
        isShow={showModalOrdersDelivery}
        setIsShow={setShowModalOrdersDelivery}
        deliveryId={responseOrder}
      />}
    </>
  );
}
